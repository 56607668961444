import type { OrganizationsUpdate } from '@orus.eu/backend/src/access/organization-store'
import { TechnicalError } from '@orus.eu/error'
import type { Organization } from '@orus.eu/right-access-management'
import deepEqual from 'deep-equal'

export type OrganizationsUpdateDiff = {
  /**
   * Technical definition of the update
   */
  update: OrganizationsUpdate
  /**
   * UI-friendly description of groups that were added in the process
   */
  addedOrganizations: Organization[]
  /**
   * UI-friendly description of groups that were removed in the process
   */
  removedOrganizations: Organization[]
  /**
   * UI-friendly description of groups that were updated in the process
   */
  updatedOrganizations: Organization[]
}

export function isOrganizationDiffEmpty(diff: OrganizationsUpdateDiff): boolean {
  return Object.keys(diff.update).length === 0
}

export function computeOrganizationDiff(a: OrganizationsUpdate, b: OrganizationsUpdate): OrganizationsUpdateDiff {
  const diff: OrganizationsUpdateDiff = {
    update: {},
    addedOrganizations: [],
    removedOrganizations: [],
    updatedOrganizations: [],
  }

  const unseenOldOrganizationNames = new Set(Object.keys(a))

  for (const entry of Object.entries(b)) {
    const technicalName = entry[0]
    const definitionA = a[technicalName]
    const definitionB = entry[1]

    unseenOldOrganizationNames.delete(technicalName)

    const {
      address,
      email,
      apiTokenHashes,
      displayName,
      legalEntity,
      orias,
      selectableProducts,
      deleted,
      hasEmbeddedFunnel,
      phone,
      canTerminateContracts,
      emailDomain,
      commissionRate,
      type,
    } = definitionB

    if (definitionA && !definitionA.deleted && definitionB.deleted) {
      diff.update[technicalName] = definitionB
      diff.removedOrganizations.push({
        technicalName,
        address,
        email,
        apiTokenHashes,
        displayName,
        legalEntity,
        orias,
        selectableProducts,
        deleted,
        hasEmbeddedFunnel,
        phone,
        canTerminateContracts,
        emailDomain,
        commissionRate,
        type,
      })
      continue
    }

    if ((!definitionA || definitionA.deleted) && !deleted) {
      diff.update[technicalName] = definitionB
      diff.addedOrganizations.push({
        technicalName,
        address,
        email,
        apiTokenHashes,
        displayName,
        legalEntity,
        orias,
        selectableProducts,
        deleted,
        hasEmbeddedFunnel,
        phone,
        canTerminateContracts,
        emailDomain,
        commissionRate,
        type,
      })
      continue
    }

    if (
      !definitionA ||
      definitionA.address !== definitionB.address ||
      !deepEqual(definitionA.apiTokenHashes, definitionB.apiTokenHashes) ||
      definitionA.deleted !== definitionB.deleted ||
      definitionA.email !== definitionB.email ||
      definitionA.hasEmbeddedFunnel !== definitionB.hasEmbeddedFunnel ||
      definitionA.phone !== definitionB.phone ||
      definitionA.displayName !== definitionB.displayName ||
      definitionA.legalEntity !== definitionB.legalEntity ||
      definitionA.orias !== definitionB.orias ||
      definitionA.canTerminateContracts !== definitionB.canTerminateContracts ||
      definitionA.emailDomain !== definitionB.emailDomain ||
      definitionA.commissionRate !== definitionB.commissionRate ||
      definitionA.type !== definitionB.type ||
      !deepEqual(definitionA.selectableProducts, definitionB.selectableProducts)
    ) {
      diff.update[technicalName] = definitionB
      diff.updatedOrganizations.push({
        technicalName,
        address,
        email,
        apiTokenHashes,
        displayName,
        legalEntity,
        orias,
        selectableProducts,
        deleted,
        hasEmbeddedFunnel,
        phone,
        canTerminateContracts,
        emailDomain,
        commissionRate,
        type,
      })
      continue
    }

    if (!deepEqual(definitionA, definitionB)) {
      throw new TechnicalError('Unhandled difference between group definitions', {
        context: { definitionA, definitionB },
      })
    }
  }

  for (const technicalName of unseenOldOrganizationNames) {
    const definitionA = a[technicalName]
    const {
      address,
      email,
      apiTokenHashes,
      displayName,
      legalEntity,
      orias,
      selectableProducts,
      deleted,
      hasEmbeddedFunnel,
      phone,
      canTerminateContracts,
      emailDomain,
      commissionRate,
      type,
    } = definitionA

    diff.update[technicalName] = { ...definitionA, deleted: true }
    diff.removedOrganizations.push({
      technicalName,
      address,
      email,
      apiTokenHashes,
      displayName,
      legalEntity,
      orias,
      selectableProducts,
      deleted,
      hasEmbeddedFunnel,
      phone,
      canTerminateContracts,
      emailDomain,
      commissionRate,
      type,
    })
  }

  return diff
}
