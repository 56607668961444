import { css } from '@emotion/react'
import type { OrganizationsUpdate } from '@orus.eu/backend/src/access/organization-store'
import { Dialog, Text, spacing } from '@orus.eu/pharaoh'
import type { Organization } from '@orus.eu/right-access-management'
import { memo, useCallback } from 'react'
import type { OrganizationsUpdateDiff } from './organizations-diff'

export type OrganizationsReviewDialogProps = {
  onSubmit: (changes: OrganizationsUpdate) => void
  onClose: () => void
  diff: OrganizationsUpdateDiff
}

export const OrganizationsReviewDialog = memo<OrganizationsReviewDialogProps>(function ChangesReviewDialog({
  onClose,
  diff,
  onSubmit,
}) {
  const handleSubmit = useCallback(() => onSubmit(diff.update), [diff.update, onSubmit])

  return (
    <Dialog
      size="large"
      onClose={onClose}
      title={'Revue des changements'}
      style="base"
      primaryActionLabel="Valider les changements"
      onPrimaryAction={handleSubmit}
    >
      <div
        css={css`
          margin-top: ${spacing[70]};
          display: flex;
          flex-direction: column;
          gap: ${spacing[50]};
        `}
      >
        <OrganizationsList organizations={diff.removedOrganizations} title="Organisations supprimées" />
        <OrganizationsList organizations={diff.addedOrganizations} title="Organisations ajoutées" />
        <OrganizationsList organizations={diff.updatedOrganizations} title="Organisations mises à jours" />
      </div>
    </Dialog>
  )
})

type OrganizationsListProps = { title: string; organizations: Organization[] }

const OrganizationsList = memo<OrganizationsListProps>(function GroupDescriptionList({ organizations, title }) {
  return organizations.length > 0 ? (
    <div>
      <Text variant="subtitle1">{title}</Text>
      <ul>
        {organizations.map((organization) => (
          <li key={organization.technicalName}>
            <Text>{organization.technicalName}</Text>
          </li>
        ))}
      </ul>
    </div>
  ) : (
    <></>
  )
})
